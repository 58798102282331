import { graphql } from "gatsby";
import Img from "gatsby-image";
import {
  ButtonBack,
  ButtonNext,
  CarouselProvider,
  DotGroup,
  Slide,
  Slider,
} from "pure-react-carousel";
import React from "react";
import Helmet from "react-helmet";
import styled from "styled-components";
import media from "styled-media-query";
import Footer from "../components/footer.js";
import Header from "../components/Header";
import Layout from "../components/layout";
import Text, { Grid } from "../components/Theme";

const Controls = styled.div`
  right: 0;
  top: 50%;
  transform: translateY(-50%);
  width: 100%;
  height: 44px;
  position: absolute;
  transition: all 100ms ease-in;
  button {
    padding: 10px;
    background-color: transparent;

    transition: all 100ms ease-in;
    color: #fff;
    border-radius: 100px;
    &:hover {
      opacity: 1;
      transform: scale(1.01);
    }
    &:disabled {
      opacity: 0;
    }
    ${media.lessThan("large")`
            padding: 15px;
        `}
  }
  .carousel__back-button {
    font-size: 24px;
    position: absolute;
    left: 20px;
    ${media.lessThan("large")`
            left: 0px;
        `}

    &:hover {
      transform: scale(1.2);
    }
    &:active {
      top: 2px;
    }
  }
  .carousel__next-button {
    font-size: 24px;
    position: absolute;

    right: 20px;

    ${media.lessThan("large")`
            right: 0px;
        `}

    &:hover {
      transform: scale(1.2);
    }
    &:active {
      top: 2px;
    }
  }
  button:focus {
    outline: none;
    box-shadow: 0px 0 0px 0px #406f64;
  }
`;

const ProductHero = styled.div`
  ${Grid};
  min-height: 70vh;

  .div-block-3 {
    grid-column: 2/7 !important;
    select {
      max-width: 200px;
    }
    button {
      background-color: #000;
      color: #fff;
      padding: 15px 60px;
      border: 2px solid black;
      margin-right: 12px;
    }
  }
  .carousel {
    &:hover {
      button {
        background: rgba(0, 0, 0, 0.4);
      }
    }
    .carousel__inner-slide {
      padding: 5px;
      ${media.lessThan("large")`
                padding: 0px;
            `}

      .gatsby-image-wrapper {
        height: 100%;
        img {
          object-fit: contain !important;
        }
      }
    }
  }
`;

const ProductDetails = styled.div`
  ${Grid};
  align-items: start;
`;

const ProductDesc = styled.div`
  padding-top: 40px;
  h3 {
    margin-bottom: 40px;
  }
`;

const Shipping = styled.div`
  grid-column: 2/13;
  padding-top: 60px;
  border-top: 1px solid rgba(0, 0, 0, 0.1);
  padding-bottom: 120px;
  h3 {
    margin-bottom: 40px;
  }
`;

// Second "Buy It Framed" button that has a white background.
const InverseButton = styled.button`
  background-color: white !important;
  border: 2px solid black;
  color: black !important;
`;

class ProductTemplate extends React.Component {
  constructor(props) {
    super(props);

    const { data } = props;

    const sizeArray =
      typeof data.datoCmsProduct.sizePrice === "string" &&
      data.datoCmsProduct.sizePrice.split("|").map((sizeStr) => {
        let [size, price] = sizeStr.split("[");

        price = price.replace("]", "");
        price = price.replace("+", "");
        price = parseFloat(price);

        return {
          size,
          price,
        };
      });

    this.state = {
      sizes: sizeArray || [],
      selectedSize: "",
    };
  }

  getPriceFromSize = (size) => {
    const index = this.state.sizes.findIndex((szObj) => szObj.size === size);

    if (index !== -1) return this.state.sizes[index].price;
  };

  dollarFormat = (amount, options = {}) => {
    const { precision = 2, returnZero = true, dollarSign = true } = options;

    const num = Number(amount);
    if (!(num || returnZero)) return "$0.00";

    const floatNum = num.toFixed(precision);

    const formattedNum = `${dollarSign ? "$" : ""}${floatNum}`;
    return formattedNum;
  };

  openLink = (link) => {
    window.open(link, "_target");
  };

  render() {
    const { data } = this.props;

    return (
      <Layout>
        <div
          hidden
          id="snipcart"
          data-api-key="NTA0ZThmMzEtYTc0Ny00MjU0LWI4NzgtNDM4NjRhMDc2ZWM5NjM2OTYyNjc0NjkyMDQyOTE4"
        ></div>

        <Helmet>
          <script src="https://cdn.snipcart.com/themes/v3.0.3/default/snipcart.js"></script>
          <link rel="stylesheet" href="../styles/snipcart.css" />
        </Helmet>
        <Header content_color="black" />

        <ProductHero>
          <div id="w-node-911e36131b53-4a37690d">
            <CarouselProvider
              naturalSlideWidth={100}
              naturalSlideHeight={125}
              totalSlides={data.datoCmsProduct.productimg.length}
              visibleSlides={1}
            >
              <Slider>
                {data.datoCmsProduct.productimg.map((img, index) => (
                  <Slide index={index} key={index}>
                    <Img fluid={img.fluid} alt="" />
                  </Slide>
                ))}
              </Slider>
              <DotGroup></DotGroup>
              <Controls>
                <ButtonBack>&larr;</ButtonBack>
                <ButtonNext>&rarr;</ButtonNext>
              </Controls>
            </CarouselProvider>
          </div>

          <div id="w-node-8dcc4f1a6824-4a37690d" className="div-block-3">
            <h1 className="headline">{data.datoCmsProduct.title}</h1>
            <div className="caption">
              {this.state.selectedSize
                ? this.dollarFormat(
                    this.getPriceFromSize(this.state.selectedSize)
                  )
                : this.dollarFormat(this.state.sizes[0].price)}
            </div>

            <form className="w-commerce-commerceaddtocartform">
              <div role="group">
                <select
                  id="option-set-11fcf8ecb4e2188de73a0485889de4a3"
                  className="caption w-select"
                  required
                  onChange={(e) => {
                    let size = e.target.value;

                    this.setState({ selectedSize: size });
                  }}
                >
                  <option value="">Select Size</option>
                  {this.state.sizes.map((szObj) => (
                    <option
                      value={szObj.size}
                      key={szObj.size}
                    >{`${szObj.size}"`}</option>
                  ))}
                </select>
              </div>
            </form>

            <button
              className="Product snipcart-add-item caption"
              data-item-image={`${data.datoCmsProduct.productimg[0].url}?fit=crop&w=200&h=200&dpr=2`}
              data-item-id={data.datoCmsProduct.slug}
              data-item-price={0}
              data-item-name={data.datoCmsProduct.title}
              data-item-url={`https://www.over-view.com/shop/${data.datoCmsProduct.slug}`}
              data-item-stackable="false"
              data-item-shippable="true"
              data-item-custom1-name="Size"
              data-item-custom1-value={this.state.selectedSize}
              data-item-custom1-options={data.datoCmsProduct.sizePrice}
              // data-item-custom1-options="10x10[+100.00]|15x15[+150.00]|20X20[+200.00]|24X24[+250.00]"
            >
              Buy Now &rarr;
            </button>
            {data.datoCmsProduct.levelFramesUrl && (
              <InverseButton
                className="Product caption"
                onClick={() =>
                  this.openLink(data.datoCmsProduct.levelFramesUrl)
                }
              >
                Buy It Framed &rarr;
              </InverseButton>
            )}
            <ProductDesc>
              <Text caption>Description</Text>
              <Text small>{data.datoCmsProduct.caption}</Text>
            </ProductDesc>
          </div>
        </ProductHero>
        <ProductDetails>
          <Shipping>
            <Text caption>SHIPPING & PRINTING INFO</Text>
            <Text small>
              Print sales are final, we do not offer returns.
              <br />
              Free shipping is included within the United States.
              <br />
              International shipping is available for an additional fee.
              <br />
              Unframed prints are completed on Epson Professional Paper by Cloud Printing in New York City.
              <br />
              Framed prints by Level Frames in Brooklyn, New York.
              <br />
              Please contact us with any questions.
            </Text>
          </Shipping>
        </ProductDetails>

        <Footer />
      </Layout>
    );
  }
}

export const query = graphql`
  query singleProductQuery($slug: String!) {
    datoCmsProduct(slug: { eq: $slug }) {
      width
      title
      source
      slug
      ratio
      sizePrice
      originalId
      height
      caption
      sizePrice
      levelFramesUrl

      productimg {
        url
        fluid(
          maxWidth: 900
          imgixParams: { dpr: 1, w: "900", fit: "clip", auto: "compress" }
        ) {
          ...GatsbyDatoCmsFluid
        }
      }
    }
  }
`;

export default ProductTemplate;
